import React from 'react';
import DropDown from '../Elements/DropDown';
import InputText from '../Elements/InputText';
import CommonVideo from '../Elements/CommonVideo';
import PhotoUpload from '../Elements/PhotoUpload';
import SignaturePad from '../Elements/SignaturePad';
import ImageView from '@pages/SelfInspection/Elements/ImageView';
const uiMapper = {
    tb : InputText,
    dd : DropDown,
    sg : SignaturePad,
    ui : PhotoUpload,
    vr : CommonVideo,
    iv: ImageView 
};
const Component = ({ index, component }) => {
    const Element = uiMapper[component?.element_type];
    
    return (
        <div>
            <Element
                componentOptions={component?.component_options}
                componentDetails={{
                    componentIndex: index,
                    componentId: component?.id,
                    componentName: component?.name,
                    type: component?.element_type
                }}
            />
        </div>
    );
};

export default Component;
