import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import SubHeader from '../Common/SubHeader';
import OrderCompletedCard from './OrderCompletedCard';
import { COMPLETED } from './Statuses';
const OrderCompleted = () => {
    const history = useHistory();
    const order = useSelector(state => state.order);

    React.useEffect(() => {
        if (order.orderStatus !== COMPLETED) {
            history.push('details');
        }
    }, [order.orderStatus]);

    return (
        <>
            <SubHeader showPageCount={false} />
            <Container className="py-4 pt-5">
                <div className="bg-body-gray">
                    <OrderCompletedCard
                        orderNumber={order.orderNumber}
                        vehicleDetails={order.vehicleDetails}
                        timeSlot={order.timeSlot}
                        customerName={order.customerName}
                        customerAddress={order.customerAddress}
                    />
                </div>
            </Container>
        </>
    );
};

export default OrderCompleted;
