import SubmitButton from '@pages/SelfInspection/Common/SubmitButton';
import { useHistory } from 'react-router-dom';
const UnderDevelopment = () => {
    const history = useHistory();
    const submitHandler = e => {
        e.preventDefault();
        history.push('dashboard');
    };
    return (
        <div className="text-center">
            <p>This Feature will be Live Soon...</p>
            <SubmitButton onClick={submitHandler}>Go To DashBoard</SubmitButton>
        </div>
    );
};

export default UnderDevelopment;
