import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './Header.css';
const Header = ({ menu }) => {
    const history = useHistory();
    return (
        <Navbar className="nav-items bg-white" sticky="top">
            <Container>
                <ul>
                    {/* <li className="pe-4">
                        <img
                            src="https://cdn1.acedms.com/photos/images/eco/dashboard/back.svg"
                            alt="ECO Inspection"
                            className="back-icon"
                            onClick={() => history.push('continue')}
                        />
                    </li> */}
                    <li className="flex-grow-1">{menu}</li>
                    <li>
                        <img
                            src="https://cdn1.acedms.com/photos/images/eco/dashboard/refresh.svg"
                            alt="Refresh the page"
                            className="refresh-icon"
                            onClick={() => history.go(0)}
                        />
                    </li>
                </ul>
            </Container>
        </Navbar>
    );
};

export default Header;
