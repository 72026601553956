import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Tutorials from '../Tutorials/Tutorials';
import PerformInspection from '../Dashboard/PerformInspection';
import OTPModel from '../Modals/OTPModal';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import * as React from 'react';

const Home = () => {
    const history = useHistory();
    const params = useParams();
    const [maskedNumber, setMaskedNumber] = useState('');
    const [otpPopUp, setOtpPopUp] = useState(false);

    const { orderNumber } = useSelector(state => state.order);

    const submitHandler = async () => {
        // e.preventDefault();
        if (orderNumber) {
            return history.push('details');
        }

        const { data } = await axios.post(`/orders/${params.orderId}/otp/send`);
        if (data.StatusCode == 1) {
            setMaskedNumber(data.Result.masked_phone);
        }
    };
    useEffect(() => {
        if (maskedNumber) {
            setOtpPopUp(true);
        }
    }, [maskedNumber]);

    const handleVerify = async otp => {
        const { data } = await axios.post(`/orders/${params.orderId}/otp/verify`, {
            otp
        });
        if (data.StatusCode == 1) {
            history.push('details');
        }
    };

    return (
        <>
            {otpPopUp ? (
                <OTPModel number={maskedNumber} handleVerify={handleVerify} submitHandler={submitHandler} />
            ) : (
                <div className="home p-4">
                    <PerformInspection submit={submitHandler} />
                    <Tutorials />
                </div>
            )}
        </>
    );
};

export default Home;
