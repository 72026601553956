import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    technicianId: null,
    orderStatus: null,
    orderNumber: null,
    vehicleDetails: null,
    timeSlot: null,
    customerName: null,
    customerAddress: null,
    aiBased: false
};
export const orderSlice = createSlice({
    name: 'orderSlice',
    initialState,
    reducers: {
        setOrderDetail: (state, action) => {
            const {
                orderNumber,
                vehicleDetails,
                timeSlot,
                customerName,
                customerAddress,
                orderStatus,
                technicianId,
                obvCategoryId,
                aiBased
            } = action.payload;
            state.obvCategoryId = obvCategoryId;
            state.technicianId = technicianId;
            state.orderStatus = orderStatus;
            state.orderNumber = orderNumber;
            state.vehicleDetails = vehicleDetails;
            state.timeSlot = timeSlot;
            state.customerName = customerName;
            state.customerAddress = customerAddress;
            state.aiBased = aiBased;
        }
    }
});
export const { setOrderDetail } = orderSlice.actions;
