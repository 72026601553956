import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    data: {},
    images: {},
    videos: {}
};

export const aiBasedSlice = createSlice({
    name: 'AIBasedSlice',
    initialState,
    reducers: {
        updateComponentFields: (state, action) => {
            const { id, name, value } = action.payload;
            if(state.data[name]){
                state.data[name] = value;
            }else{
                state.data = {...state.data,[name]:value}
            }
        },
        updateImages: (state, action) => {
            state.images[action.payload.name] = [action.payload.image];
        },
        resetImages: (state, action) => {
            state.images[action.payload.name] = [];
        },
        updateVideos: (state, action) => {
            state.videos[action.payload.name] = [action.payload.video];
        },
        resetVideos: (state, action) => {
            state.videos[action.payload.name] = []
        },
        resetSlice : () => initialState
    }
});

export const { updateComponentFields, updateImages, resetImages, resetVideos, updateVideos,resetSlice } = aiBasedSlice.actions;
