import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
const cardImg = 'https://cdn1.droom.in/photos/images/eco/dashboard/tutorials-img.png';

const Tutorials = () => {
    const history = useHistory();

    return (
        <div className="tutorials" onClick={() => history.push('tutorials')}>
            <Card style={{ width: '100%', borderRadius: '1rem', overflow: 'hidden' }}>
                <Card.Img variant="top" src={cardImg} />
                <Card.Body>
                    <Card.Title className="text-center fs-2">Tutorials</Card.Title>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Tutorials;
