import React, { useRef, useState } from 'react';

const UploadImage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleUpload = () => {
    
    if (selectedFile) {
      console.log('Selected file:', selectedFile);
    } else {
      console.log('No file selected');
    }
  };

  return (
    <div className='d-flex flex-column align-items-center'>
      <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
      <div className='border d-flex justify-content-center align-items-center flex-column rounded-4 fs-6 text-dark p-4 pt-2 pb-2 h-25'
        onClick={() => fileInputRef.current.click()}>
        <div>
          <img src="https://cdn1.acedms.com/photos/images/droomresponsive/img/content/buyers-1/uploadphoto.png" alt="" />
        </div>
        <div className='text-center fs-12'>Upload <br /> Image</div>
      </div>
      {previewImage && (
        <div className='mt-3'>
          <img src={previewImage} alt='Preview' style={{ maxWidth: '100%', maxHeight: '300px' }} />
        </div>
      )}
    </div>
  );
};

export default UploadImage;
