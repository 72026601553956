import React, { useRef } from 'react';

const TakeVideo = ({handleVideoChange}) => {
  const inputRef = useRef(null);
  const videoRef = useRef(null);

  const handleCapture = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  // const handleFileChange = async (event) => {
  //   const file = event.target.files[0];
  //   if (!file) return;

  //   const video = videoRef.current;
  //   const videoURL = URL.createObjectURL(file);

  //   if (video) {
  //     video.src = videoURL;
  //     video.play();
  //   }
  // };

  return (
    <div className='d-flex flex-column align-items-center m-2'>
      <input
        ref={inputRef}
        type='file'
        accept='video/*'
        capture='user'
        onChange={handleVideoChange}
        style={{ display: 'none' }}
      />
      <div onClick={handleCapture} className='border d-flex justify-content-center align-items-center flex-column rounded-4 fs-6 text-dark p-4 pt-2 pb-2 h-25'>
        <div>
          <img src="https://cdn1.acedms.com/photos/images/droomresponsive/img/content/buyers-1/photo.png" alt="" />
        </div>
        <div className='text-center fs-12'>Take <br /> Video</div>
      </div>
      <video ref={videoRef} style={{ display: 'none', width: '100%', maxWidth: '300px' }} controls />
    </div>
  );
};

export default TakeVideo;
