import React, { Fragment } from 'react';
import Parameter from '@pages/SelfInspection/AI/Parameter/Parameter';

const Screen = ({ parameters }) => {
    return (
        <>
            {parameters?.map((parameter, index) => {
                return (
                    <Fragment key={index}>
                        <Parameter key={parameter.id} components={parameter?.components} />
                    </Fragment>
                );
            })}
            <Parameter />
        </>
    );
};

export default Screen;
