import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import SubHeader from '../Common/SubHeader';
import SubmitButton from '../Common/SubmitButton';
import OrderBody from './OrderBody';
import { ACCEPTED } from './Statuses';

const OrderStart = () => {
    const history = useHistory();
    const params = useParams();
    const order = useSelector(state => state.order);

    const submitHandler = () => {
        axios.post(`/orders/${params.orderId}/inspection/start`).then(res => {
            if (res.data.StatusCode == 1) {
                history.push('continue');
            }
        });
    };

    React.useEffect(() => {
        if (order.orderStatus !== ACCEPTED) {
            history.push('details');
        }
    }, [order.orderStatus]);

    return (
        <>
            <SubHeader showPageCount={false} />
            <Container className="py-4 pt-5">
                <div className="bg-white">
                    <OrderBody
                        orderNumber={order?.orderNumber}
                        vehicleDetails={order?.vehicleDetails}
                        timeSlot={order?.timeSlot}
                        customerName={order?.customerName}
                        customerAddress={order?.customerAddress}
                    />
                    <div className="p-3">
                        <SubmitButton className="w-100" onClick={submitHandler}>
                            Start Inspection
                        </SubmitButton>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default OrderStart;
