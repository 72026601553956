import { Fragment, useState } from 'react';
import TakeImage from './TakeImage';
import UploadImage from './UploadImage';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { resetImages, updateImages } from '@redux/AIRedux/aiBasedSlice';
import imageCompression from 'browser-image-compression';
import TickSign from './TickSign';

const PhotoUpload = ({ componentOptions, componentDetails }) => {
    const { is_required = 0, upload_source } = componentOptions;
    const { componentId, componentName } = componentDetails;
    const dispatch = useDispatch();
    const { images } = useSelector(state => state.aiServiceData);
    const { images: imageFromSlice } = useSelector(state => state.aiBased);
    const compressionOptions = {
        maxSizeMB: process.env.COMPRESS_IMAGE_MAX_SIZE || 0.5,
        maxWidthOrHeight: process.env.COMPRESS_IMAGE_MAX_HW || 900,
        useWebWorker: true
    };
    const selectedFiles = imageFromSlice?.[componentName] || images?.[componentName] || [];
    const requiredFile = parseInt(is_required) && !selectedFiles.length ? 1 : 0;
    const handleImageChange = e => {
        dispatch(resetImages({ name: componentName }));
        Array.from(e.target.files).forEach(file => {
            if (parseInt(process.env.COMPRESS_IMAGE || 1)) {
                imageCompression(file, compressionOptions).then(blob => {
                    dispatch(
                        updateImages({
                            name: componentName,
                            image: new File([blob], file.name, { type: file.type })
                        })
                    );
                });
            } else {
                dispatch(
                    updateImages({
                        name: componentName,
                        image: file
                    })
                );
            }
        });
    };

    return (
        <div>
            <Fragment>
                <Form.Label>{componentName}</Form.Label>
                {upload_source == 'c' ? (
                    <div className="d-flex gap-1 ">
                        <div>
                            <TakeImage handleImageChange={handleImageChange} required={requiredFile} />
                        </div>
                        {(imageFromSlice?.[componentName] || images?.[componentName]) && <TickSign />}
                    </div>
                ) : (
                    <UploadImage />
                )}
                <Form.Control.Feedback type="invalid">
                    {selectedFiles.length ? '' : 'Please select a file.'}
                </Form.Control.Feedback>
            </Fragment>
        </div>
    );
};
export default PhotoUpload;
