import { Fragment } from 'react';
import TakeVideo from './TakeVideo';
import VideoUpload from './VideoUpload';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { resetVideos, updateVideos } from '@redux/AIRedux/aiBasedSlice';
import TickSign from './TickSign';
const CommonVideo = ({ componentOptions, componentDetails }) => {
    const { is_required = 0, upload_source } = componentOptions;
    const {componentName } = componentDetails;
    const {videos} = useSelector(state =>state.aiServiceData);
    const {videos: videosSlice} = useSelector (state => state.aiBased);

    const dispatch = useDispatch();
    const handleVideoChange =(e) =>{
        dispatch(resetVideos({name:componentName}))
        Array.from(e.target.files).forEach(file => {
            dispatch(
                updateVideos({
                    name: componentName,
                    video: file
                })
            );
        });
    }
    return (
        <div>
            <Fragment>
                <Form.Label>{componentName}</Form.Label>
                {upload_source == 'c' ? (
                    <div className="d-flex gap-1 ">
                    <div>
                        <TakeVideo handleVideoChange={handleVideoChange}/>
                    </div>
                    { (videos[componentName] || videosSlice[componentName])&& 
                        <TickSign/>
                    }
                </div>
                    
                ) : (
                    <VideoUpload handleVideoChange={handleVideoChange} />
                )}
            </Fragment>
        </div>
    );
}
export default CommonVideo;