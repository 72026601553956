import React from 'react';
import { useSelector } from 'react-redux';
import { ACTIVE, COMPLETED } from '../Order/Statuses';

const RangeSliderWithLabels = () => {
    const { orderStatus } = useSelector(state => state.order);

    return (
        <div className="p-4 px-0 text-white">
            {/* <ul className="status-bar d-flex justify-content-between">
        <li className="active"></li>
        <li></li>
        <li></li>
      </ul> */}
            <ul className="d-flex justify-content-between mt-2 status-bar">
                <li className={`text-center lh-sm ${orderStatus === ACTIVE ? 'active' : ''}`}>
                    Order <br />
                    Request
                </li>
                <li className={`text-center lh-sm ${![ACTIVE, COMPLETED].includes(orderStatus) ? 'active' : ''}`}>
                    Orders In <br />
                    Progress
                </li>
                <li className={`text-center lh-sm ${orderStatus === COMPLETED ? 'active' : ''}`}>
                    Orders <br />
                    Completed
                </li>
            </ul>
        </div>
    );
};

export default RangeSliderWithLabels;
